import {Card, Image, HStack, VStack, Text, Button} from "@chakra-ui/react";
import {IStock} from "../../models/stock";
import React, {useEffect, useState} from "react";
import {AddIcon, MinusIcon} from '@chakra-ui/icons'

interface Props {
  stock: IStock;
  selectedStock?: { stockId: number, quantity: number };
  addStock: (stockId: number) => void;
  removeStock: (stockId: number) => void;
}

export const StockCard: React.FC<Props> = ({stock, removeStock, selectedStock, addStock}) => {

  return <Card key={stock.id} margin="10px 0" bgColor={"#585858"} padding="10px 5px" color={"#fff"}>
    <HStack gap={5}>
      <Image flex={3} height={100} width={100} fit={"contain"} src={stock.imageUrl.split(',')[0]}/>
      <VStack flex={5} alignItems={"flex-start"}>
        <Text>{stock.name}</Text>
        <Text>{stock.category.heading}</Text>
        <Text color={"green.500"}>{stock.qty} in Stock</Text>
      </VStack>
      <VStack flex={3}>
        {(!selectedStock || selectedStock?.quantity === 0) && <Button fontSize={12} onClick={() => {
          addStock(stock.id);
        }}>Add</Button>}

        {selectedStock && selectedStock?.quantity !== 0 && <HStack gap={2} w="100%">
            <Button bg="red" color="white" size={"sm"} rounded={100} onClick={() => {
              removeStock(stock.id);
            }}><MinusIcon cursor={'pointer'}/></Button>
            <Text>{selectedStock?.quantity||0}</Text>
            <Button bg="green" color="white" size={"sm"} rounded={100} onClick={() => {
              if (selectedStock?.quantity === stock.qty) return;
              addStock(stock.id);
            }}><AddIcon cursor={'pointer'}/></Button>


        </HStack>}
      </VStack>
    </HStack>
  </Card>
}

import {CreateProject} from "../pages/CreateProject";
import * as _ from "lodash";
import useStocks from "../../hooks/useStocks";
import { useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {IJob} from "../../models/job";
import {
  useCreateJobMutation,
  useGetJobByIdQuery,
  useLazyGetStockByIdQuery,
  useUpdateJobMutation
} from "../../redux/stores/apiSlice";
import {Skeleton, useDisclosure, useToast} from "@chakra-ui/react";
import {CreateJobConfirmationDialog} from "../components/CreateJobConfirmationDialog";
import {useNavigate} from "react-router";
import {ChatContainer} from "./ChatContainer";
import {selectStockOwner} from "../../redux/stores/stockSlice";
import {util} from "zod";
import objectValues = util.objectValues;
import {uniqBy} from "lodash";

interface Props {
  jobId?: number;
}

export const ProjectView: React.FC<Props> = ({jobId}) => {
  const [selectedStocks, setSelectedStocks] = useState<{ stockId: number, quantity: number }[]>([]);
  const {data, isLoading,} = useGetJobByIdQuery(jobId!, {
    skip: !jobId,
  }, );
  const [createJob] = useCreateJobMutation();
  const [updateJob] = useUpdateJobMutation();
  const {isOpen, onClose, onOpen} = useDisclosure();
  const [job, setJob] = useState<Partial<IJob> | undefined>(data);
  const navigate = useNavigate();
  const {stocks, loading, loadMoreStocks, setSearchQuery, setOwner} = useStocks();
  const owner = useSelector(selectStockOwner);
  const debounce = _.debounce(setSearchQuery!, 200);
  const toast = useToast();
  const [totalCost, setTotalCost] = useState(0);

  const addStock = async (stockId: number) => {
    const updated = [...selectedStocks.filter(s => s.stockId !== stockId), {
      stockId,
      quantity: (selectedStocks.find(s => s.stockId === stockId)?.quantity || 0) + 1
    }];
    let total = totalCost;
    const stock = stocks.find(s => s.id === stockId);
    if(owner !== "Nandos") {
      if(stock?.virtual) {
        let autoTotal = 0;
        for (const a of stock.autopulls) {
          autoTotal = autoTotal+ +a.price!;
        }
        total = total + autoTotal;
      } else {
        total = total + +stock!.priceA
      }
    }
    setTotalCost(total);
    setSelectedStocks(uniqBy([...updated], s => s.stockId));
  }
  const removeStock = (stockId: number) => {
    let updated;
    if(selectedStocks.find(s => s.stockId === stockId)?.quantity === 1) {
      updated = selectedStocks.filter(s => s.stockId !== stockId);
    } else {
      updated = [...selectedStocks.filter(s => s.stockId !== stockId), {
        stockId,
        quantity: (selectedStocks.find(s => s.stockId === stockId)?.quantity  || 0) - 1
      }];
    }
    let total = totalCost;
    const stock = stocks.find(s => s.id === stockId);
    if(owner !== "Nandos") {
      if(stock?.virtual) {
        let autoTotal = 0;
        for (const a of stock.autopulls) {
          autoTotal = autoTotal - +a.price!;
        }
        total = total - autoTotal;
      } else {
        total = total - +stock!.priceA
      }
    }
    setTotalCost(total);
    setSelectedStocks(uniqBy(updated, s => s.stockId));
  }

  const onSubmit = (updates: Partial<IJob>) => {
    if(!updates.name?.trim()) {
      toast({
        title: 'Please fill in details.',
        status: 'error',
        duration: 1000,
        isClosable: true,
      })
      return;
    }
    setJob(updates);
    if (updates.poNumber?.trim() === "") {
      if(updates.stocks?.length === 0) {
        toast({
          title: 'Please select some stock.',
          status: 'error',
          duration: 1000,
          isClosable: true,
        })
        return;
      }
      onConfirm(updates);
    } else {
      onOpen();
    }
  }

  const onConfirm = async (updates?: Partial<IJob>) => {
    if (!!jobId) {
      const {error} = await updateJob({id: jobId, ...updates! ?? job!});
      if (error) {
        toast({
          title: 'Error in updating job.',
          status: 'error',
          duration: 1000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Job updated successfully.',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      }
    } else {
      const {error} = await createJob(updates!);
      if (error) {
        toast({
          title: 'Error in creating job.',
          status: 'error',
          duration: 1000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Job updated successfully.',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      }
    }
    navigate(-1);
  }
  useEffect(() => {
    if (data)
      setSelectedStocks(data.stocks);
  }, [data]);
  const {isOpen: isChatOpen, onClose: onChatClose, onOpen: onChatOpen} = useDisclosure();
  const [chatJobId, setChatJobId] = useState<number>();
  const openChat = (id: number) => {
    setChatJobId(id);
    onChatOpen();
  }
  return <>
    {isLoading ? <Skeleton/> : <>
      <CreateProject
          goBack={() => {
            navigate(-1);
          }}
          totalCost={totalCost}
          isLoadingStocks={loading}
          job={data}
          onLoadMoreStocks={loadMoreStocks!}
          createJob={onSubmit}
          removeStock={removeStock}
          selectedStocks={selectedStocks}
          stocks={stocks}
          onSearch={debounce}
          addStock={addStock}
          setOwner={setOwner!}
          openChat={openChat}
      />
      <CreateJobConfirmationDialog
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={() => onConfirm()}
      />
      <ChatContainer
          jobId={chatJobId}
          isOpen={isChatOpen}
          onClose={onChatClose}
      />
    </>}
  </>

}

import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';

interface ConfirmationDialogProps {
    modalHeader: string;
    modalBody: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirmation: (data?: any) => void;
    data?: any;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
    props: ConfirmationDialogProps,
) => {
    const {
        data,
        modalBody,
        modalHeader,
        isOpen,
        onClose,
        onConfirmation,
    } = props;

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
        >
            <ModalOverlay />
            <ModalContent p="2% 1%" borderRadius="2xl">
                <ModalHeader fontSize="1.3rem" fontWeight="800">
                    {modalHeader}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>{modalBody}</ModalBody>
                <ModalFooter>
                    <Button
                        variant="ghost"
                        onClick={onClose} mr="10px"
                        colorScheme={"red"}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="solid"
                        colorScheme="green"
                        onClick={onConfirmation}
                    >
                        Yes, confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmationDialog;

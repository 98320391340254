import { ICustomCategory } from "../models/category";
import React, {
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import useApi from "../hooks/useApi";
import * as _ from "lodash";
import useAuth from "../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategories,
  getSelectedCategory,
  getTotal,
  loadedCategories,
  setSelectedCategory,
  deleteCategory as deleteCategoryAction
} from "../redux/stores/categorySlice";
import { useToast } from "@chakra-ui/react";

interface ICategoryContext {
  categories: ICustomCategory[];
  loadCategories?: () => Promise<void>;
  selectedCategory?: ICustomCategory;
  setSelectedCategory?: (v?: ICustomCategory) => void;
  loading: boolean;
  addCategory?: (name: string) => Promise<void>;
  deleteCategory?: (id: string) => Promise<void>;
}

export const CategoryContext = React.createContext<ICategoryContext>({
  categories: [],
  loadCategories: undefined,
  selectedCategory: undefined,
  setSelectedCategory: undefined,
  loading: false,
  addCategory: undefined,
  deleteCategory: undefined,
});

const CategoryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const categories = useSelector(getAllCategories)
  const { get, post, destroy } = useApi();
  const dispatch = useDispatch();
  const toast = useToast();
  const selectedCategory = useSelector(getSelectedCategory);
  const [loading, setLoading] = useState(false);
  const total = useSelector(getTotal);
  const { user } = useAuth();
  const loadCategories = async (refresh=false) => {
    if (total === categories.length && total !== 0 && !refresh) return;
    if (loading) return;
    setLoading(true);
    const res = await get(
      `/stocks/customCategories`
    );
    const d: ICustomCategory[] = res;
    dispatch(loadedCategories(res))
    setLoading(false);
  };

  const addCategory = async (name: string) => {
    try {
      const res = await post(
        `/stocks/customCategories`,
        { name }
      );
      loadCategories(true);
      toast({
        title: 'Category added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to add category',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        status: 'error', 
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const deleteCategory = async (id: string) => {
    try {
      const res = await destroy(
        `/stocks/customCategories/${id}`
      );
      dispatch(deleteCategoryAction(id));
      toast({
        title: 'Category deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch(err) {
      toast({
        title: 'Failed to delete category',
        description: err instanceof Error ? err.message : 'Unknown error occurred',
        status: 'error', 
        duration: 3000,
        isClosable: true,
      });
    }
  
  };

  useEffect(() => {
    if (!!user) loadCategories();
  }, [user]);
  return (
    <CategoryContext.Provider
      value={{
        categories,
        loadCategories,
        selectedCategory,
        setSelectedCategory: (c) => dispatch(setSelectedCategory(c)),
        loading,
        addCategory,
        deleteCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;

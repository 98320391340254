import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxGroup, Flex,
  Heading,
  HStack, IconButton,
  Image, Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text, Textarea,
  Th,
  Thead, Tooltip,
  Tr, useToast, VStack
} from "@chakra-ui/react";
import useAuth from "../../hooks/useAuth";
import {Role} from "../../models/user";
import {useSelector} from "react-redux";
import {getStockById} from "../../redux/stores/stockSlice";
import {Form, Formik} from "formik";
import {ImagePicker} from "./components/ImagePicker";
import {ICustomCategory} from "../../models/category";
import {IAutoPullStock} from "../../models/autopull_stock";
import {
  useFetchDamageReportByStockQuery,
  useGetStockByIdQuery,
  useUpdateDamageReportsMutation
} from "../../redux/stores/apiSlice";
import {CheckIcon, ChevronLeftIcon, CloseIcon} from "@chakra-ui/icons";
import {DamageReportStatus} from "../../models/damageReports";
import {IStock} from "../../models/stock";

interface Props {
  stockId?: number;
  showBackButton?: boolean;
  autoPullStock?: IAutoPullStock;
  customCategories: ICustomCategory[];
  isOpen: boolean;
  isUpdating: boolean;
  onClose: () => void;
  onBack?: () => void;
  onSelectAutoPullStock: (autopull: IAutoPullStock) => void;
  updateStockFunction: (options: { categoryIds?: string[], file?: File }, stock: IStock) => Promise<void>;
}


export const EquipmentDetails: React.FC<Props> = ({
                                                    stockId,
                                                    isOpen,
                                                    onClose,
                                                    showBackButton,
                                                    onBack,
                                                    updateStockFunction,
                                                    customCategories,
                                                    isUpdating,
                                                    autoPullStock,

                                                    onSelectAutoPullStock
                                                  }) => {
  const {user} = useAuth();
  const toast = useToast()

  const {data: stock, isLoading} = useGetStockByIdQuery(stockId?? -1);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [uploadImage, setUploadImage] = useState<File>();
  const {data: damageReport} = useFetchDamageReportByStockQuery(stockId!, {
    skip: !stockId,
  });
  const [updateDamageReport, {isSuccess}] = useUpdateDamageReportsMutation();
  const [cost, setCost] = useState<number>()
  const [damageReportDescription, setDamageReportDescription] = useState<string>()

  useEffect(() => {
    setSelectedImage(stock?.imageUrl.split(',')[0]);
    setUploadImage(undefined);
  }, [stockId, stock?.imageUrl]);

  useEffect(() => {
    if (damageReport) {
      setDamageReportDescription(damageReport.description);
      setCost(damageReport.cost);
    }
  }, [damageReport]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Updated damage report!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    }
  }, [toast, isSuccess]);

  return (
      <Modal onClose={onClose} size={'4xl'} isOpen={isOpen}>
        <ModalOverlay/>
        <Formik enableReinitialize initialValues={{
          imageUrl: stock?.imageUrl,
          customCategoryIds: stock?.customCategories?.map(c => `${c.id}`) ?? []
        }} onSubmit={(values) => {
          updateStockFunction({
            file: uploadImage,
            categoryIds: values.customCategoryIds.map(e => e)
          }, stock!).then(v => setUploadImage(undefined));
        }}>
          {
            (formikProps) => {
              return <Form>
                <ModalContent padding="12px 20px">
                  <ModalCloseButton/>
                  <ModalBody onClick={(e) => {

                  }}>
                    <SimpleGrid columns={2} spacingX="20px" spacingY="20px">
                      <Stack direction={'column'} gap="10px" height="600px" onClick={onClose}>
                        <Box>
                          <Image
                              height="400px"
                              width="100%"
                              src={selectedImage}
                              alt={stock?.name}
                              fit="contain"
                              border="1px"
                          />
                          {showBackButton && !!onBack &&
                              <IconButton aria-label={'go back'} icon={<ChevronLeftIcon/>} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onBack();
                              }}
                                          position={"absolute"} top={6} left={12}/>}
                        </Box>
                        <HStack overflowX={"scroll"} overflowY={"visible"} spacing={10}>{
                          formikProps.values.imageUrl?.split(',').map(e => {
                            return <Image
                                key={e}
                                height="70px"
                                width="70px"
                                border={
                                  selectedImage === e ? '1px' : 'none'
                                }
                                src={e}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setSelectedImage(e);
                                }}
                                fit="contain"
                            />
                          })

                        }
                        </HStack>
                        {
                            (user?.role !== Role.USER) && <ImagePicker onImagesSelect={(file: File) => {
                              setUploadImage(file);
                              formikProps.setFieldValue('imageUrl', formikProps.values.imageUrl + ',' + URL.createObjectURL(file));
                            }}/>
                        }
                      </Stack>
                      <Stack height="100%" overflow="scroll" gap="20px" direction="column">
                        <Heading as="h6" size="md" noOfLines={1}>
                          {stock?.name}
                        </Heading>
                        <Popover>
                          <PopoverTrigger>
                            <Button>Choose Category</Button>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow/>
                            <PopoverCloseButton/>
                            <PopoverHeader>Choose Category!</PopoverHeader>
                            <PopoverBody>
                              <CheckboxGroup value={formikProps.values.customCategoryIds} colorScheme='green'
                                             onChange={(v) => {
                                               formikProps.setFieldValue('customCategoryIds', v);
                                               console.log('checkbox group', formikProps.values.customCategoryIds);
                                             }}>
                                <Stack direction='column'>
                                  {
                                    customCategories.map(c => {
                                      return <Checkbox key={c.id}
                                                       isChecked={formikProps.values.customCategoryIds.includes(`${c.id}`)}
                                                       checked={formikProps.values.customCategoryIds.includes(`${c.id}`)}
                                                       value={`${c.id}`}>{c.name}</Checkbox>
                                    })
                                  }
                                </Stack>
                              </CheckboxGroup>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>

                        {stock?.virtual ?
                            <TableContainer>
                              <Table layout={"fixed"} w="100%" variant='striped' colorScheme='gray' size={"sm"}>
                                <Thead>
                                  <Tr>
                                    <Th>Name</Th>
                                    <Th isNumeric>Qty</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {
                                    isLoading ? [1, 2, 3, 4, 5, 6, 7].map(e => {
                                          return <Tr cursor="pointer" key={e}>
                                            <Td>
                                              <Skeleton/>
                                            </Td>
                                            <Td><Skeleton/></Td>
                                            <Td><Skeleton/></Td>
                                          </Tr>
                                        }) :
                                        stock?.autopulls?.length > 0 ? stock?.autopulls?.map(vs => {
                                          return <Tr cursor="pointer" key={vs.id}
                                                     onClick={() => onSelectAutoPullStock(vs)}>
                                            <Td overflow="hidden" textOverflow={"ellipsis"}>{vs.name}</Td>
                                            <Td isNumeric>{vs.qty}</Td>
                                          </Tr>
                                        }) : <Center w={'100%'}>
                                          No Stocks Found
                                        </Center>
                                  }
                                </Tbody>
                              </Table>

                            </TableContainer> :
                            <>
                              <Text>Alternative Name: {stock?.altName}</Text>
                              <Text>Category: {stock?.category?.heading}</Text>
                              <Text>Stock Status: {stock?.status}</Text>
                              <Text>Stock Quantity: {autoPullStock?.qty || stock?.qty}</Text>
                              <Text>Replacement Cost: £{(+(autoPullStock?.price??'0')).toFixed(2) || (+(stock?.priceA??'0')).toFixed(2)}</Text>
                              <Text>Description: {stock?.description}</Text>
                              <Text>Length: {stock?.length ?? "N/A"}</Text>
                              <Text>Width: {stock?.width ?? "N/A"}</Text>
                              <Text>Height: {stock?.height ?? "N/A"}</Text>
                              <Text>Weight: {stock?.weight ?? "N/A"}</Text>
                              <Text>PAT Interval: {stock?.patInterval ?? "N/A"}</Text>
                              <Text>Test Interval: {stock?.testInterval ?? "N/A"}</Text>
                            </>
                        }


                      </Stack>
                    </SimpleGrid>
                  </ModalBody>
                  {(user?.role !== Role.USER) &&
                      <Flex flexDirection="row-reverse">
                          <Button isLoading={isUpdating} type={"submit"}>Save Changes</Button>
                      </Flex>
                  }
                  <VStack spacing={2}>
                    <Heading alignSelf={"flex-start"} size={"md"} color={"red"} my="12px">Damage Report</Heading>
                    <Textarea
                        placeholder={"Damage report description"}
                        value={damageReportDescription || ""}
                        onChange={(event) => setDamageReportDescription(event.target.value)}
                    />
                    <HStack alignSelf={"flex-start"} spacing={2}>
                      <Input
                          placeholder={"Cost to repair"}
                          disabled={user?.role !== Role.SUPER_ADMIN}
                          value={cost || ""}
                          onChange={(event) => setCost(+event.target.value)}
                      />
                      <Button
                          alignSelf='flex-end'
                          onClick={() => {
                            updateDamageReport({id: stockId, description: damageReportDescription, cost: cost});
                          }}
                      >Submit</Button>
                      {
                        damageReport?.cost &&
                          <HStack>
                              <Tooltip label='Accept' bg='green' color='white'>
                                  <IconButton
                                      colorScheme='green'
                                      aria-label='Accept'
                                      onClick={() => {
                                        updateDamageReport({id: stockId, status: DamageReportStatus.APPROVED});
                                      }}
                                      icon={<CheckIcon/>}
                                  />
                              </Tooltip>
                              <Tooltip label='Reject' bg='red' color='white'>
                                  <IconButton
                                      colorScheme='red'
                                      aria-label='Reject'
                                      onClick={() => {
                                        updateDamageReport({id: stockId, status: DamageReportStatus.CANCELLED});
                                      }}
                                      icon={<CloseIcon/>}
                                  />
                              </Tooltip>
                          </HStack>
                      }

                    </HStack>
                  </VStack>
                </ModalContent>
              </Form>
            }
          }
        </Formik>
      </Modal>

  );
};

import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {ICustomCategory} from "../../models/category";
import {RootState} from "./AppStore";


export const categoryEntityAdapter = createEntityAdapter<ICustomCategory>();
export const categorySlice = createSlice(
    {
      name: 'categories',
      initialState: {
        selectedCategory: undefined as unknown as ICustomCategory| undefined,
        ...categoryEntityAdapter.getInitialState()
      },
      reducers: {
        loadedCategories: categoryEntityAdapter.addMany,
        deleteCategory: categoryEntityAdapter.removeOne,
        setSelectedCategory: (state, action: { payload: ICustomCategory| undefined}) => {
          state.selectedCategory = action.payload;
        },
      },
      selectors: {
        getSelectedCategory: sliceState => {
          return sliceState.selectedCategory;
        },

      }
    }
);
export const {
  selectAll: getAllCategories,
  selectById: getCategoryById,
  selectTotal: getTotal
} = categoryEntityAdapter.getSelectors<RootState>(state => state.categories);

const categoryState = (state: RootState) => state.categories;
export const {
  getSelectedCategory
} = categorySlice.getSelectors<RootState>(state => state.categories);
export const categoryReducer = categorySlice.reducer;
export const {setSelectedCategory, loadedCategories, deleteCategory} = categorySlice.actions;

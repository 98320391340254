import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {Config} from "../../config";
import {IJob, IJobResponse} from "../../models/job";
import {IDamageReport} from "../../models/damageReports";
import {RequestOptions} from "../../hooks/useApi";
import {ICompany} from "../../models/company";
import {IStock} from "../../models/stock";
import {IUser} from "../../models/user";
import {IMessage} from "../../models/message";
import {IDashboard} from "../../models/dashboard";

const setToken = (token: string) => {
  return localStorage.setItem("secure_token", token);
};

const deleteToken = () => {
  return localStorage.removeItem("secure_token");
};

const getToken = () => {
  return localStorage.getItem("secure_token");
};
const getHeaders = (config?: RequestOptions) => {
  if (config && config.useAuthHeaders === false) {
    return config.headers;
  }
  const token = getToken();
  return {
    ...(config || {}).headers,
    Authorization: `${token}`,
  };
};

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: Config.API_BASE_URL,
    headers: getHeaders(),
  }),
  tagTypes: ['damageReports', 'jobs', 'jobChat'],
  endpoints: (build) => ({
    dashboard: build.query<IDashboard, null>({
      query: () => ({url: `/dashboard`}),
      providesTags: ['jobs', 'damageReports']
    }),
    loadJobs: build.query<IJob[], null>({
      query: () => ({url: '/jobs'}),
      transformResponse: (baseQueryReturnValue: IJobResponse[], meta, arg) => {
        console.log(baseQueryReturnValue);
        return baseQueryReturnValue.map(j => {
          console.log('[Job transformation]');
          return {
            ...j,
            setupDate: j.setupDate ? new Date(j.setupDate) : undefined,
            liveDate: j.liveDate ? new Date(j.liveDate) : undefined,
            finishDate: j.finishDate ? new Date(j.finishDate) : undefined,
            total: +(j.total ?? '0')
          };
        });
      },
      providesTags: ['jobs']
    }),
    fetchAllDamageReports: build.query<IDamageReport[], null>({
      query: () => ({url: '/stocks/damageReports'}),
      providesTags: ["damageReports"]
    }),
    fetchDamageReportByStock: build.query<IDamageReport, number>({
      query: (stockId: number) => ({url: `/stocks/${stockId}/damageReports`}),
      providesTags: ['damageReports']
    }),
    updateDamageReports: build.mutation<IDamageReport, Partial<IDamageReport>>({
      query: ({id, ...patch}) => ({
        url: `/stocks/${id}/damageReports`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: ['damageReports']
    }),
    deleteDamageReports: build.mutation<IDamageReport, number>({
      query: (id) => ({
        url: `/stocks/${id}/damageReports`,
        method: 'DELETE',
      }),
      invalidatesTags: ['damageReports']
    }),
    createJob: build.mutation<IJob, Partial<IJob>>({
      query: (args) => {
        console.log('create job mutation body', args);
        return {url: '/jobs', method: 'POST', body: args};
      },
      transformResponse: (baseQueryReturnValue: IJobResponse, meta, arg) => {
        return {
          ...baseQueryReturnValue,
          setupDate: baseQueryReturnValue.setupDate ? new Date(baseQueryReturnValue.setupDate) : undefined,
          liveDate: baseQueryReturnValue.liveDate ? new Date(baseQueryReturnValue.liveDate) : undefined,
          finishDate: baseQueryReturnValue.finishDate ? new Date(baseQueryReturnValue.finishDate) : undefined,
          total: +(baseQueryReturnValue.total ?? '0')
        };
      },
      invalidatesTags: (job) => [{type: 'jobs', id: job?.id}]
    }),
    updateJob: build.mutation<IJob, Partial<IJob> & Pick<IJob, 'id'>>({
      query: ({id, ...patch}) => ({url: `/jobs/${id}`, method: 'PUT', body: patch}),
      transformResponse: (baseQueryReturnValue: IJobResponse, meta, arg) => {
        return {
          ...baseQueryReturnValue,
          setupDate: baseQueryReturnValue.setupDate ? new Date(baseQueryReturnValue.setupDate) : undefined,
          liveDate: baseQueryReturnValue.liveDate ? new Date(baseQueryReturnValue.liveDate) : undefined,
          finishDate: baseQueryReturnValue.finishDate ? new Date(baseQueryReturnValue.finishDate) : undefined,
          total: +(baseQueryReturnValue.total ?? '0')
        };
      },
      invalidatesTags: (job) => [{type: 'jobs', id: job?.id}]
    }),
    getCompanies: build.query<ICompany[], null>({
      query: () => ({url: '/companies'})
    }),
    getCompanyById: build.query<ICompany, number>({
      query: (id: number) => ({url: `/companies/${id}`})
    }),
    getJobById: build.query<IJob, number>({
      query: (id: number) => ({url: `/jobs/${id}`}),
      transformResponse: (baseQueryReturnValue: IJob, meta, arg) => {
        return {
          ...baseQueryReturnValue,
          setupDate: baseQueryReturnValue.setupDate ? new Date(baseQueryReturnValue.setupDate) : undefined,
          liveDate: baseQueryReturnValue.liveDate ? new Date(baseQueryReturnValue.liveDate) : undefined,
          finishDate: baseQueryReturnValue.finishDate ? new Date(baseQueryReturnValue.finishDate) : undefined,
          total: +(baseQueryReturnValue.total ?? '0')
        };
      },
      providesTags: (job) => [{type: 'jobs', id: job?.id}]
    }),
    getStockById: build.query<IStock, number>({
      query: (id: number) => ({url: `/stocks/${id}`}),

    }),
    getJobChat: build.query<IMessage[], number>({
      query: (id: number) => ({url: `/jobs/${id}/chats`}),
      providesTags: ['jobChat']
    }),
    sendJobChat: build.mutation<IMessage, { id: number, text: string }>({
      query: ({id, text}) => ({
        url: `/jobs/${id}/chats`,
        method: 'POST',
        body: {text}
      }),
      invalidatesTags: ['jobChat']
    }),
    getUserById: build.query<IUser, number>({
      query: (id: number) => ({url: `/users/${id}`}),
    }),
  })
});


export const {
  useFetchAllDamageReportsQuery,
  useUpdateDamageReportsMutation,
  useGetCompaniesQuery,
  useLazyGetCompanyByIdQuery,
  useLoadJobsQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useFetchDamageReportByStockQuery,
  useGetJobByIdQuery,
  useGetStockByIdQuery,
  useLazyGetStockByIdQuery,
  useGetJobChatQuery,
  useSendJobChatMutation,
  useDashboardQuery,
  useDeleteDamageReportsMutation,
} = apiSlice;

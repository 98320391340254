import {useEffect, useState} from "react";
import useApi from "../../hooks/useApi";
import {ISignupSchema, IUser} from "../../models/user";
import {AdminControl} from "../pages/AdminControl";
import useAuth from "../../hooks/useAuth";
import {useToast} from "@chakra-ui/react";

export const AdminControlView = () => {
  const {get, post, put, destroy} = useApi();
  const auth = useAuth();
  const toast = useToast();
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const loadUsers = () => {
    get("/users/")
        .then((v) => {
          setUsers(v as IUser[]);
        })
        .finally(() => {
          setLoading(false);
        });
  }
  useEffect(() => {
    setLoading(true);
    loadUsers()
  }, []);

  const createUser = async (schema: ISignupSchema) => {
    const res = await post(`/companies/${auth.user!.companyId}/invite`, schema);
    setUsers((users) => [res.user, ...users]);
  };

  const resetPassword = async (userId: number) => {
    const res = await put(`/users/reset-password/${userId}`, {});
    if (res.success) {
      toast({
        title: "Success",
        description: "Temporary password is sent to user's registered Email Id.",
      });
    }
  };

  const deleteUser = async (userId: number) => {
    const res = await destroy(`/users/${userId}`, {});
    if (res.success) {
      toast({
        title: "Success",
        description: "User deleted successfully!",
      });
      loadUsers();
    }
  };
  return (
      <AdminControl
          resetPassword={resetPassword}
          loading={loading}
          users={users}
          createUser={createUser}
          deleteUser={deleteUser}
      />
  );
};

import React, {PropsWithChildren, useEffect, useState} from "react";
import {IStock, IStockUpdateSchema} from "../models/stock";
import useApi from "../hooks/useApi";
import useCategory from "../hooks/useCategory";
import useAuth from "../hooks/useAuth";
import {
  moreStockLoaded,
  selectStockOwner,
  selectStockQuery,
  stocksLoaded,
  stocksQuery,
  stocksUser,
  selectStockLoading,
  selectAllStocks,
  stocksLoading,
  stockUpdated, stockAdded, stocksLoadingMore,
} from "../redux/stores/stockSlice";
import {useDispatch, useSelector} from "react-redux";
import {useToast} from "@chakra-ui/react";

interface IStockContext {
  loadStocks?: () => Promise<void>;
  loadMoreStocks?: () => Promise<void>;
  stocks: IStock[];
  loading: boolean;
  setSearchQuery?: (q: string) => void;
  setOwner?: (owner: string) => void;
  setCategory?: (id?: number) => void;
  updateStock?: (id: number, updates: Partial<IStockUpdateSchema>) => Promise<void>;
}

export const StockContext = React.createContext<IStockContext>({
  loadStocks: undefined,
  loadMoreStocks: undefined,
  stocks: [],
  loading: false,
  setSearchQuery: undefined,
  setOwner: undefined,
  updateStock: undefined,
});

const StockProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {get,put} = useApi();
  const toast = useToast();
  const {user} = useAuth();
  const {selectedCategory} = useCategory();
  const dispatch = useDispatch();
  const query = useSelector(selectStockQuery);
  const stocks = useSelector(selectAllStocks);
  const owner = useSelector(selectStockOwner);
  const loading = useSelector(selectStockLoading);
  const loadStocks = async () => {
    dispatch(stocksLoading(true));
    let url: string = `/stocks?limit=20&offset=0`;
    if (!!query) {
      url = url + `&search=${query}`;
    }
    if (!!owner) {
      url = url + `&owner=${owner}`;
    }
    if (selectedCategory) {
      url = url + `&customCategoryId=${selectedCategory?.id}`;
    }
    const res = await get(url);
    const d: IStock[] = res.data;
    dispatch(stocksLoaded(d));
    dispatch(stocksLoading(false));
  };
  const loadMoreStocks = async () => {
    if (loading) return;
    dispatch(stocksLoadingMore(true));
    let url: string = `/stocks?limit=20&offset=${stocks.length}`;
    if (!!query) {
      url = url + `&search=${query}`;
    }

    if (selectedCategory) {
      url = url + `&customCategoryId=${selectedCategory?.id}`;
    }

    if (!!owner) {
      url = url + `&owner=${owner}`;
    }
    try {
      const res = await get(url);
      const d: IStock[] = res.data;
      dispatch(moreStockLoaded(d));
      dispatch(stocksLoadingMore(false));
    } catch (e) {
      console.log(e);
    }
  };



  const updateStock = async (id: number, changes: Partial<IStockUpdateSchema>) => {
     await put(`/stocks/${id}`, changes).then((changes: IStock) => {
      dispatch(stockUpdated({id, changes}));
    }).then(v => {
      toast({
        title: 'Update Stock success',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
     });
  }
  useEffect(() => {
    if (!!user) loadStocks();
  }, [query, selectedCategory, owner, user]);
  return (
      <StockContext.Provider
          value={{
            stocks,
            loadStocks,
            loading,
            setSearchQuery: (v) => dispatch((stocksQuery(v))),
            loadMoreStocks,
            setOwner: (v) => {
              dispatch((stocksUser(v)));
            },
            updateStock,
          }}
      >
        {children}
      </StockContext.Provider>
  );
};

export default StockProvider;

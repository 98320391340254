import { ICustomCategory } from "../../../models/category";
import { Table, Thead, Tbody, Tr, Th, Td, IconButton } from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

interface CategoryListPageProps {
  categories: ICustomCategory[];
  addCategory: (name: string) => Promise<void>;
  deleteCategory: (id: string) => Promise<void>;
}

const CategoryListPage: React.FC<CategoryListPageProps> = ({ categories, addCategory, deleteCategory }) => {
  return (
    <>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Category Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {categories.map((category) => (
            <Tr key={category.id}>
              <Td>{category.name}</Td>
              <Td>
                <IconButton
                  aria-label="Delete category"
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  size="sm"
                  onClick={() => {
                    deleteCategory(category.id);
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <IconButton
        aria-label="Add category"
        icon={<AddIcon />}
        colorScheme="blue"
        size="lg"
        isRound
        position="fixed"
        bottom={8}
        right={8}
        onClick={() => {
          const name = window.prompt("Enter category name:");
          if (name) {
            addCategory(name);
          }
        }}
      />
    </>
  );
};

export default CategoryListPage;
